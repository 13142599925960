<template>
  <div id="app">
    <Main />
  </div>
</template>

<script>
import Main from "./layouts/Main";

export default {
  name: "App",
  components: {
    Main
  }
};
</script>

<style>
</style>
