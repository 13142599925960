<template>
  <div>
      <div class="stage">
        <div class="wrapper">
            <div class="slash"></div>
            <div class="sides">
                <div class="side"></div>
                <div class="side"></div>
                <div class="side"></div>
                <div class="side"></div>
            </div>
            <div class="text">
                <div class="text--backing">
                    <div class="image">
                        <img src="../assets/images/loading-img.webp" alt="">
                        
                    </div>
                </div>
                <div class="text--left">
                    <div class="inner">
                        <div class="image">
                            <img src="../assets/images/loading-img.webp" alt="">
                            
                        </div>
                    </div>
                </div>
                <div class="text--right">
                    <div class="inner">
                        <div class="image">
                            <img src="../assets/images/loading-img.webp" alt="">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

.stage {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    // opacity: 0.8;
}
.wrapper {
  position: relative;
  color: #fff;
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  padding-top: 0.65rem;
//   padding-left: 0.5rem;
//   padding-right: 0.36rem;  
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 0.65rem;
}
.image {
    width: 2em;
    height: 2em;
}
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.sides {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
.sides .side {
  position: absolute;
  background: #fff;
}
.sides .side:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 0.15rem;
  transform: translateX(-101%);
  animation: side-top ease 3s infinite;
}
.sides .side:nth-child(2) {
  top: 0;
  right: 0;
  width: 0.15rem;
  height: 100%;
  transform: translateY(-101%);
  animation: side-right ease 3s infinite;
}
.sides .side:nth-child(3) {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.15rem;
  transform: translateX(101%);
  animation: side-bottom ease 3s infinite;
}
.sides .side:nth-child(4) {
  top: 0;
  left: 0;
  width: 0.15rem;
  height: 100%;
  transform: translateY(101%);
  animation: side-left ease 3s infinite;
}
.text {
  position: relative;
}
.text--backing {
  opacity: 0;
}
.text--left {
  position: absolute;
  top: 0;
  left: 0;
  width: 51%;
  height: 100%;
  overflow: hidden;
}
.text--left .inner {
  transform: translateX(100%);
  animation: text-left 4s ease-in-out infinite;
}
.text--right {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
}
.text--right .inner {
  transform: translateX(-200%);
  animation: text-right 4s ease-in-out infinite;
}
@-moz-keyframes slash {
  0% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  13% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  16.6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
}
@-webkit-keyframes slash {
  0% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  13% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  16.6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
}
@-o-keyframes slash {
  0% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  13% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  16.6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
}
@keyframes slash {
  0% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  13% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(1);
  }
  16.6% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-24deg) scaleY(0);
  }
}
@-moz-keyframes text-left {
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(0);
  }
  58% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}
@-webkit-keyframes text-left {
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(0);
  }
  58% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}
@-o-keyframes text-left {
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(0);
  }
  58% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}
@keyframes text-left {
  0% {
    transform: translateX(100%);
  }
  10% {
    transform: translateX(0);
  }
  58% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}
@-moz-keyframes text-right {
  0% {
    transform: translateX(-200%);
  }
  10% {
    transform: translateX(-100%);
  }
  58% {
    transform: translateX(-100%);
  }
  70% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-300%);
  }
}
@-webkit-keyframes text-right {
  0% {
    transform: translateX(-200%);
  }
  10% {
    transform: translateX(-100%);
  }
  58% {
    transform: translateX(-100%);
  }
  70% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-300%);
  }
}
@-o-keyframes text-right {
  0% {
    transform: translateX(-200%);
  }
  10% {
    transform: translateX(-100%);
  }
  58% {
    transform: translateX(-100%);
  }
  70% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-300%);
  }
}
@keyframes text-right {
  0% {
    transform: translateX(-200%);
  }
  10% {
    transform: translateX(-100%);
  }
  58% {
    transform: translateX(-100%);
  }
  70% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-300%);
  }
}
@-moz-keyframes side-top {
  0%, 14% {
    transform: translateX(-101%);
  }
  24%, 55% {
    transform: translateX(0);
  }
  65% {
    transform: translateX(101%);
  }
  100% {
    transform: translateX(101%);
  }
}
@-webkit-keyframes side-top {
  0%, 14% {
    transform: translateX(-101%);
  }
  24%, 55% {
    transform: translateX(0);
  }
  65% {
    transform: translateX(101%);
  }
  100% {
    transform: translateX(101%);
  }
}
@-o-keyframes side-top {
  0%, 14% {
    transform: translateX(-101%);
  }
  24%, 55% {
    transform: translateX(0);
  }
  65% {
    transform: translateX(101%);
  }
  100% {
    transform: translateX(101%);
  }
}
@keyframes side-top {
  0%, 14% {
    transform: translateX(-101%);
  }
  24%, 55% {
    transform: translateX(0);
  }
  65% {
    transform: translateX(101%);
  }
  100% {
    transform: translateX(101%);
  }
}
@-moz-keyframes side-right {
  0%, 14%, 23% {
    transform: translateY(-101%);
  }
  30%, 62% {
    transform: translateY(0);
  }
  72% {
    transform: translateY(101%);
  }
  100% {
    transform: translateY(101%);
  }
}
@-webkit-keyframes side-right {
  0%, 14%, 23% {
    transform: translateY(-101%);
  }
  30%, 62% {
    transform: translateY(0);
  }
  72% {
    transform: translateY(101%);
  }
  100% {
    transform: translateY(101%);
  }
}
@-o-keyframes side-right {
  0%, 14%, 23% {
    transform: translateY(-101%);
  }
  30%, 62% {
    transform: translateY(0);
  }
  72% {
    transform: translateY(101%);
  }
  100% {
    transform: translateY(101%);
  }
}
@keyframes side-right {
  0%, 14%, 23% {
    transform: translateY(-101%);
  }
  30%, 62% {
    transform: translateY(0);
  }
  72% {
    transform: translateY(101%);
  }
  100% {
    transform: translateY(101%);
  }
}
@-moz-keyframes side-bottom {
  0%, 14%, 24%, 28% {
    transform: translateX(101%);
  }
  37%, 70% {
    transform: translateX(0);
  }
  79% {
    transform: translateX(-101%);
  }
  100% {
    transform: translateX(-101%);
  }
}
@-webkit-keyframes side-bottom {
  0%, 14%, 24%, 28% {
    transform: translateX(101%);
  }
  37%, 70% {
    transform: translateX(0);
  }
  79% {
    transform: translateX(-101%);
  }
  100% {
    transform: translateX(-101%);
  }
}
@-o-keyframes side-bottom {
  0%, 14%, 24%, 28% {
    transform: translateX(101%);
  }
  37%, 70% {
    transform: translateX(0);
  }
  79% {
    transform: translateX(-101%);
  }
  100% {
    transform: translateX(-101%);
  }
}
@keyframes side-bottom {
  0%, 14%, 24%, 28% {
    transform: translateX(101%);
  }
  37%, 70% {
    transform: translateX(0);
  }
  79% {
    transform: translateX(-101%);
  }
  100% {
    transform: translateX(-101%);
  }
}
@-moz-keyframes side-left {
  0%, 14%, 24%, 34%, 35% {
    transform: translateY(101%);
  }
  44%, 79% {
    transform: translateY(0);
  }
  86% {
    transform: translateY(-101%);
  }
  100% {
    transform: translateY(-101%);
  }
}
@-webkit-keyframes side-left {
  0%, 14%, 24%, 34%, 35% {
    transform: translateY(101%);
  }
  44%, 79% {
    transform: translateY(0);
  }
  86% {
    transform: translateY(-101%);
  }
  100% {
    transform: translateY(-101%);
  }
}
@-o-keyframes side-left {
  0%, 14%, 24%, 34%, 35% {
    transform: translateY(101%);
  }
  44%, 79% {
    transform: translateY(0);
  }
  86% {
    transform: translateY(-101%);
  }
  100% {
    transform: translateY(-101%);
  }
}
@keyframes side-left {
  0%, 14%, 24%, 34%, 35% {
    transform: translateY(101%);
  }
  44%, 79% {
    transform: translateY(0);
  }
  86% {
    transform: translateY(-101%);
  }
  100% {
    transform: translateY(-101%);
  }
}

</style>