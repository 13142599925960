<template>
	<base-modal id="globalMessageModal" v-bind:is-visible.sync="isVisible">
		<div class="magure-modal">
			<div class="magure-modal-header">
				<h2>{{ title }}</h2>
			</div>
			<div class="magure-modal-body">
				<p v-html="message"></p>
			</div>
			<div class="magure-modal-footer" v-if="type === 1">
				<button class="btn btn-primary" v-on:click="close()">OK</button>
			</div>
			<div class="magure-modal-footer text-center" v-else-if="type === 2">
				<button class="btn btn-secondary" v-on:click="close()">Cancel</button>&nbsp;
				<button class="btn btn-primary" v-on:click="confirm()">Confirm</button>
			</div>
		</div>
	</base-modal>
</template>

<script>
	
	import BaseModal from './BaseModal.vue';
    import MessageService from '../services/MessageService';
	import MessageType from '../lib/message-type';

	export default {
		components: {
			BaseModal
		},
		data() {
			return {
                isVisible: false,
				message: '',
				title: '',
				type: MessageType.OK
			};
		},
		methods: {
            close() {
				MessageService.close();
			},
			confirm() {
				MessageService.confirm();
			},
            init() {
				var that = this;

				MessageService.onClose(function () {
					that.isVisible = false;
				});

				MessageService.onShow(function () {
					var message = MessageService.getMessage();
					var title = MessageService.getTitle();
					var type = MessageService.getType();

					that.message = message;
					that.title = title;
					that.type = type;
					that.isVisible = true;
				});
			}
		},
		mounted() {
            this.init();
		}
	};
</script>

<style lang="scss" scoped>
button {
    width: 180px !important;
    height: 60px !important;
    font-size: 1.2em !important;
}
</style>