import MessageType from '../lib/message-type';

const MessageService = (function () {
	'use strict';

	var _onCloseHooks = [];
	var _onConfirm = null;
	var _onShowHooks = [];
	var _message = '';
	var _title = '';
	var _type = MessageType.OK;

	var close;
	var confirm;

	var getMessage;
	var getTitle;
	var getType;

	var _onClose;
	var onClose;
	var _onShow;
	var onShow;

	var show;
	var showConfirmation;
	var showError;

	close = function () {
		_onConfirm = null;
		_type = MessageType.OK;

		_onClose();
	};

	confirm = () => {
		if (_onConfirm && typeof _onConfirm === 'function') {
			_onConfirm();
		}

		close();
	};

	getMessage = function () {
		return _message;
	};

	getTitle = function () {
		return _title;
	};

	getType = () => {
		return _type;
	};

	_onClose = function () {
		for (var i = 0; i < _onCloseHooks.length; i += 1) {
			_onCloseHooks[i]();
		}
	};

	onClose = function (cb) {
		_onCloseHooks.push(cb);
	};

	_onShow = function () {
		for (var i = 0; i < _onShowHooks.length; i += 1) {
			_onShowHooks[i]();
		}
	};

	onShow = function (cb) {
		_onShowHooks.push(cb);
	};

	show = function (message, title) {
		if (!title) {
			title = '';
		}

		_message = message;
		_title = title;

		_onShow();
	};

	showConfirmation = (message, title, onConfirm) => {
		_onConfirm = onConfirm;
		_type = MessageType.Confirmation;

		show(message, title);
	};

	showError = function (message) {
		show(message, 'Error');
	};

	return {
		close: close,

		confirm: confirm,

		getMessage: getMessage,
		getTitle: getTitle,
		getType: getType,

		onClose: onClose,
		showConfirmation: showConfirmation,
		onShow: onShow,

		show: show,
		showError: showError,
	};
})();

export default MessageService;

