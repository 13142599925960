<template>
  <div
    data-aos="fade-in"
    data-aos-delay="200"
    data-aos-once="true"
    class="app-navigation"
    :class="colorNav ? 'shadow-sm': ''"
    :style="{'background-color': colorNav ? '#fff' : ''}"
  >
    <div class="container no-tb-margin pl-0 d-flex justify-content-between">
      <div class="logo">
        <router-link to="/">
          <img
            data-aos="zoom-in-right"
            data-aos-delay="200"
            alt="logo.png"
            src="../assets/images/PNG_MAGUREINC.webp"
            title="Magure"
          />
          <span class="logo-text" :class="checkIndex">MAGURE</span>
        </router-link>
      </div>
      <a class="app-navigation-toggle my-auto" v-on:click="expand()">
        <menu-icon></menu-icon>
      </a>
      <div
        class="app-navigation-collapse"
        v-bind:class="{ 'is-collapsed': isCollapsed, 'slide-in-from-right': isExpanding, 'slide-out-to-right': isCollapsing }"
      >
        <a class="app-navigation-close" v-on:click="collapse()">
          <!-- <x-icon></x-icon> -->
          X
        </a>
        <ul class="links">
          <li v-on:click="collapse">
            <router-link to="/services" active-class="active-link" :class="checkIndex">Services</router-link>
          </li>
          <li v-on:click="collapse">
            <router-link
              to="/products"
              :class="[aiPages,checkIndex]"
              active-class="active-link"
            >Products</router-link>
          </li>
          <li v-on:click="collapse">
            <router-link to="/about-us" active-class="active-link" :class="checkIndex">About Us</router-link>
          </li>
          <li v-on:click="collapse">
            <router-link to="/careers" active-class="active-link" :class="checkIndex">Careers</router-link>
          </li>
          <li v-on:click="collapse" class="d-block d-lg-none d-xl-none">
            <router-link to="/contact-us" :class="checkIndex">Talk Now</router-link>
          </li>
          <li v-on:click="collapse" class="ml-5 d-none d-lg-block d-xl-block">
            <router-link to="/contact-us" class="button-rounded">Talk Now</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import MenuIcon from "../icons/MenuIcon";
import { setTimeout } from "timers";

export default {
  components: {
    MenuIcon
  },
  data() {
    return {
      isCollapsed: true,
      isCollapsing: false,
      isExpanding: false,
      currentRoute: "",
      isActiveLink: false,
      colorNav: false,
      darkBackground: []
    };
  },
  methods: {
    collapse() {
      this.isCollapsing = true;
      setTimeout(() => {
        this.isCollapsing = false;
        this.isCollapsed = true;
      }, 100);
    },
    expand() {
      this.isCollapsed = false;
      this.isExpanding = true;
      setTimeout(() => {
        this.isExpanding = false;
      }, 100);
    },
    checkRoute() {
      if (this.$route.path !== "/") {
        this.colorNav = true;
        this.darkBackground = [];
      } else {
        this.colorNav = false;
        this.darkBackground = [1, 2, 3, 4, 5, 6, 8]; // ADD INDEX OF THE DARK SLIDES
      }
    }
  },
  watch: {
    $route() {
      this.checkRoute();
    }
  },
  mounted() {
    this.checkRoute();
  },
  computed: {
    aiPages() {
      const pages = [
        "/face-detection",
        "/age-prediction",
        "/celebrity-recognition",
        "/emotion-detection",
        "/object-detection",
        "/face-spoofing-detection",
        "/face-compare",
        "/weapon-detection",
        "/Wheelchair-detection"
      ];

      if (pages.includes(this.$route.path)) {
        return "active-link";
      }
      return "";
    },

    checkIndex() {
      if (this.darkBackground.includes(this.slideIndex)) {
        return "text-white";
      }
      return "text-black";
    }
  },
  props: {
    slideIndex: {
      type: [Number, Function]
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../styles/components/AppNavigation.scss";
</style>