<template>
  <div class="home">
    <swiper
      class="swiper parallax-slider"
      :options="swiperOption"
      ref="mySwiper"
      @slideChange="onSlideChange()"
      :modules="modules"
      :pagination="{ clickable: true }"
    >
      <!-- slide one -->
      <swiper-slide virtualIndex="1" class="swiper-slide parallax-slide">
        <div class="parallax-slide-image slide-one" data-swiper-parallax="70%">
          <container-section>
            <div>
              <slide-one
                :slideOneTitleOne="'A step closer to'"
                :slideOneTitleTwo="'simplified software'"
                :slideOneTitleThree="'solutions.'"
                :slideOneSideImage="'slide-one-title-img.webp'"
                :titleOnly="false"
              />
            </div>
          </container-section>
        </div>
      </swiper-slide>

      <!-- slide Huawei -->
      <swiper-slide virtualIndex="2" class="swiper-slide parallax-slide">
        <div class="parallax-slide-image slide-huawei" data-swiper-parallax="70%">
          <container-section>
            <div>
              <slide-huawei />
            </div>
          </container-section>
        </div>
      </swiper-slide>

      <!-- slide verizon -->
      <swiper-slide virtualIndex="3" class="swiper-slide parallax-slide">
        <div class="parallax-slide-image slide-verzion" data-swiper-parallax="70%">
          <container-section>
            <div class>
              <slide-verizon />
            </div>
          </container-section>
        </div>
      </swiper-slide>

      <!-- slide FourC -->
      <swiper-slide virtualIndex="4" class="swiper-slide parallax-slide">
        <div class="parallax-slide-image slide-fourc" data-swiper-parallax="70%">
          <container-section>
            <div class>
              <slide-fourc />
            </div>
          </container-section>
        </div>
      </swiper-slide>

      <!-- slide two -->
      <!-- <swiper-slide virtualIndex="5" class="swiper-slide parallax-slide">
        <div class="parallax-slide-image slide-two" data-swiper-parallax="70%">
          <container-section>
            <div class>
              <slide-two />
            </div>
          </container-section>
        </div>
      </swiper-slide>-->

      <!-- slide three -->
      <swiper-slide virtualIndex="6" class="swiper-slide parallax-slide">
        <div class="parallax-slide-image slide-three" data-swiper-parallax="70%">
          <container-section>
            <div class>
              <slide-three />
            </div>
          </container-section>
        </div>
      </swiper-slide>

      <!-- slide four -->
      <swiper-slide virtualIndex="7" class="swiper-slide parallax-slide">
        <div class="parallax-slide-image slide-four" data-swiper-parallax="70%">
          <container-section>
            <div class="lg:w-4/5">
              <common-slide
                :title="'Facilitating Sports Entertainment in Kenya'"
                :description="'Entertaining more than a million customers in Kenya with engaging sports betting android app'"
                :btnText="'Get in Touch'"
                :btnPath="'/contact-us'"
                :isWhite="true"
              />
            </div>
          </container-section>
        </div>
      </swiper-slide>

      <!-- slide six -->
      <swiper-slide virtualIndex="8" class="swiper-slide parallax-slide">
        <div class="parallax-slide-image slide-six" data-swiper-parallax="70%">
          <container-section>
            <div class="lg:w-4/5">
              <common-slide
                :title="'End to End app for the Government of India'"
                :description="'We have actively worked on multiple applications for the Government of India'"
                :btnText="'Get in Touch'"
                :btnPath="'/contact-us'"
                :isWhite="true"
                :isSlideDivided="false"
                :sideImage="''"
              />
            </div>
          </container-section>
        </div>
      </swiper-slide>

      <!-- slide seven -->
      <swiper-slide virtualIndex="9" class="swiper-slide parallax-slide">
        <div class="parallax-slide-image slide-seven" data-swiper-parallax="70%">
          <container-section>
            <div class>
              <common-slide
                :title="'Artificially Intelligent Models'"
                :description="'Experience the future ready software with our pretrained models.'"
                :btnText="'Learn More'"
                :btnPath="'/products'"
                :isWhite="false"
                :isSlideDivided="true"
                :sideImage="'Software-engineer.svg'"
                :slideLogo="''"
              />
            </div>
          </container-section>
        </div>
      </swiper-slide>

      <!-- slide eight -->
      <swiper-slide virtualIndex="10" class="swiper-slide parallax-slide">
        <div class="parallax-slide-image slide-eight" data-swiper-parallax="70%">
          <container-section>
            <div class>
              <common-slide
                :title="'App for Heart Stent Company'"
                :description="'Increasing productivity of Heart Stent Company \'Relisys\' with our services'"
                :btnText="'Get in Touch'"
                :isWhite="true"
                :btnPath="'/contact-us'"
                :isSlideDivided="true"
                :sideImage="'Heart-Handshake.svg'"
              />
            </div>
          </container-section>
        </div>
      </swiper-slide>

      <!-- slide nine -->
      <swiper-slide virtualIndex="11" class="swiper-slide parallax-slide">
        <div class="parallax-slide-image slide-nine" data-swiper-parallax="70%">
          <container-section>
            <testimonals-carousel />
          </container-section>
        </div>
      </swiper-slide>

      <!-- slide ten -->
      <swiper-slide virtualIndex="12" class="swiper-slide parallax-ten">
        <div class="parallax-slide-image slide-ten" data-swiper-parallax="70%">
          <container-section>
            <slide-world-map />
          </container-section>
        </div>
      </swiper-slide>

      <!-- slide eleven -->
      <swiper-slide virtualIndex="13" class="swiper-slide parallax-ten">
        <div class="parallax-slide-image slide-eleven relative" data-swiper-parallax="70%">
          <container-section class="mb-20">
            <slide-clocks />
          </container-section>
          <div class>
            <Footer class="absolute bottom-0 right-0 left-0"></Footer>
          </div>
        </div>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import SwiperCore, { Parallax, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.min.css";
import SessionService from "../services/SessionService";

const SlideOne = () =>
  import(/* webpackChunkName : 'SlideOne' */ "../components/Home/SlideOne.vue");

const ContainerSection = () =>
  import(
    /* webpackChunkName : 'ContainerSection' */ "../components/ContainerSection.vue"
  );
// const SlideTwo = () =>
//   import(/* webpackChunkName : 'SlideTwo' */ "../components/Home/SlideTwo.vue");
const SlideHuawei = () =>
  import(
    /* webpackChunkName : 'SlideTwo' */ "../components/Home/SlideHuawei.vue"
  );
const SlideVerizon = () =>
  import(
    /* webpackChunkName : 'SlideTwo' */ "../components/Home/SlideVerizon.vue"
  );
const SlideFourc = () =>
  import(
    /* webpackChunkName : 'SlideTwo' */ "../components/Home/SlideFourc.vue"
  );
const SlideThree = () =>
  import(
    /* webpackChunkName : 'SlideThree' */ "../components/Home/SlideThree.vue"
  );
const CommonSlide = () =>
  import(
    /* webpackChunkName : 'CommonSlide' */ "../components/Home/CommonSlide.vue"
  );
const TestimonalsCarousel = () =>
  import(
    /* webpackChunkName : 'TestimonalsCarousel' */ "../components/Home/Testimonals/TestimonalsCarousel.vue"
  );
const SlideWorldMap = () =>
  import(
    /* webpackChunkName : 'SlideWorldMap' */ "../components/Home/SlideWorldMap.vue"
  );
const SlideClocks = () =>
  import(
    /* webpackChunkName : 'SlideClocks' */ "../components/Home/SlideClocks.vue"
  );
const Footer = () =>
  import(/* webpackChunkName : 'Footer' */ "../components/Home/Footer.vue");

SwiperCore.use(Parallax);

export default {
  title: "Magure - Home",

  components: {
    Swiper,
    SwiperSlide,
    SlideHuawei,
    SlideVerizon,
    SlideFourc,
    // SlideTwo,
    SlideOne,
    ContainerSection,
    SlideThree,
    CommonSlide,
    TestimonalsCarousel,
    SlideWorldMap,
    SlideClocks,
    Footer
  },
  setup() {
    return {
      modules: [Pagination]
    };
  },

  data() {
    return {
      activeSlideIndex: 0,
      swiperOption: {
        direction: "vertical",
        slidesPerView: 1,
        mousewheel: true,
        parallax: true,
        loop: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        keyboard: {
          enabled: true,
          onlyInViewport: false
        },
        speed: 1050
      },
      isVisible: false
    };
  },
  methods: {
    onSlideChange() {
      this.activeSlideIndex = this.$refs.mySwiper.$swiper.activeIndex;
      console.log(this.activeSlideIndex, "AIndex");
      this.$emit("slide-change", this.activeSlideIndex);
    },

    init() {
      let email = "";
      let passcode = "";
      SessionService.setClientEmailId(email);
      SessionService.setPasscode(passcode);
    }
  },
  mounted() {
    this.init();
    this.$emit("slide-change", 0);
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/components/Home/Home.scss";

.parallax-slider {
  position: relative;
}
.parallax-slide {
  // height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.parallax-slide-image {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;

  // display: flex;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>