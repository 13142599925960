<template>
	<div class="base-layout" v-bind:class="{ 'has-overflow-hidden': hasOverflowHidden }">
		<slot></slot>

		<global-message-modal></global-message-modal>
	</div>
</template>

<script>

	import GlobalMessageModal from '../components/GlobalMessageModal';
	// import SignalService from '../services/signal-service';

	export default {
		components: {
			GlobalMessageModal
		},

		data() {
			return {
				hasOverflowHidden: false
			};
		},

		methods: {
			init() {

				
			}
		},

		mounted() {
			this.init();
		}
	};
</script>

<style lang="scss">
	@import "../styles/layouts/Base.scss";
</style>