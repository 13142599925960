<template>
	<base-layout>
		<div v-if="isLoading">
			<loading-component></loading-component>
		</div>
		<div v-else>

			<div class="clearfix"></div>
			<div >
				<AppNavigation :slide-index="activeSlideIndex" />
				<transition @before-enter="scrollTop" name="fade" mode="out-in" appear>
					<router-view v-on:slide-change="showIndex"></router-view>
				</transition>
				<slot></slot>
			</div>
		</div>
	</base-layout>
</template>

<script>
	import AppNavigation from "../components/AppNavigation";
	import LoadingComponent from '../components/LoadingComponent.vue';
	import BaseLayout from "../layouts/Base";

	export default {
		components: {
			AppNavigation,
			BaseLayout,
			LoadingComponent
		},
		data() {
			return {
				isLoading: true,
				activeSlideIndex:Number
			}
		},
		methods: {
		showIndex(e) {
			this.activeSlideIndex = e;
		},
		scrollTop(){
			document.getElementById('app').scrollIntoView();
		},
		tidio() {
			let tidio = document.createElement('script');    tidio.setAttribute('src',"//code.tidio.co/mlcwyf8lzqybvov7tvn784nshzhd42wb.js");
			document.head.appendChild(tidio);
		}
		},
		mounted() {
			setTimeout(() => {
			this.isLoading = false;
			}, 3000);
		},
		created() {
			setTimeout(() => {
			this.tidio();
			}, 5000);
		},
		props: {
			
		}
	};
</script>

<style lang="scss">
	@import "../styles/layouts/main.scss";
</style>