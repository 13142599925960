const SessionService = (function () {
    var clearEmailId;
    var clearPasscode;
    var getClientEmaiId;
    var getPasscode;
    var getIsCurrentClientGivenEmail;
    var getIsPasscodePresent;
    var setClientEmailId;
    var setPasscode;

    clearEmailId = () => {
        window.localStorage.setItem("clientEmailId","");
    }

    clearPasscode = () => {
        window.localStorage.setItem("passcode","");
    }

    getClientEmaiId = () => {
        return localStorage.clientEmailId;
    }

    getPasscode = () => {
        return localStorage.passcode;
    }

    getIsCurrentClientGivenEmail = () => {
        if(localStorage.clientEmailId && localStorage.clientEmailId !== "") {
            return true;
        }
        return false;
    }
    
    getIsPasscodePresent = () => {
        if(localStorage.passcode && localStorage.passcode !== "") {
            return true;
        }
        return false;
    }

    setClientEmailId = (email) => {
        localStorage.clientEmailId = email;
    }

    setPasscode = (code) => {
        localStorage.passcode = code;
    }

    return {
        clearEmailId:clearEmailId,
        clearPasscode:clearPasscode,
        getClientEmaiId: getClientEmaiId,
        getPasscode: getPasscode,
        getIsPasscodePresent: getIsPasscodePresent,
        getIsCurrentClientGivenEmail: getIsCurrentClientGivenEmail,
        setPasscode: setPasscode,
        setClientEmailId: setClientEmailId
    }
})();

export default SessionService;