import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
// import VueAnalytics from 'vue-analytics';
import VueGtag from 'vue-gtag';
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Mixins
import TitleMixin from './mixins/TitleMixin';

import { Swiper as SwiperClass, Mousewheel, Pagination, Keyboard } from 'swiper/core';
// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
SwiperClass.use([Mousewheel,Pagination,Keyboard]);

import LazyLoadDirective from "./directives/LazyLoadDirectives";


import Home from './Pages/Home.vue';

const Services = () =>  import (/* webpackChunkName : 'Expertise' */ './Pages/Services.vue');
const AboutUs = () =>  import (/* webpackChunkName : 'AboutUs' */ './Pages/AboutUs.vue');
const Careers = () =>  import (/* webpackChunkName : 'Careers' */ './Pages/Careers.vue');
const ContactUS = () =>  import (/* webpackChunkName : 'ContactUs' */ './Pages/ContactUs.vue');
const Products = () =>  import (/* webpackChunkName : 'Products' */ './Pages/Products.vue');
const FaceDetection = () =>  import (/* webpackChunkName : 'FaceDetection' */ './Pages/FaceDetection.vue');
const AgeGenderprediction = () =>  import (/* webpackChunkName : 'AgeGenderprediction' */ './Pages/AgeGenderprediction.vue');
const CelebrityRecognition = () =>  import (/* webpackChunkName : 'CelebrityRecognition' */ './Pages/CelebrityRecognition.vue');
const EmotionDetection = () =>  import (/* webpackChunkName : 'EmotionDetection' */ './Pages/EmotionDetection.vue');
const ObjectDetection = () =>  import (/* webpackChunkName : 'ObjectDetection' */ './Pages/ObjectDetection.vue');
const FaceSpoofingDetection = () =>  import (/* webpackChunkName : 'FaceSpoofingDetection' */ './Pages/FaceSpoofingDetection.vue');
const FaceCompare = () =>  import (/* webpackChunkName : 'FaceCompare' */ './Pages/FaceCompare.vue');
const WeaponDetection = () =>  import (/* webpackChunkName : 'WeaponDetection' */ './Pages/WeaponDetection.vue');
const WheelchairDetection = () =>  import (/* webpackChunkName : 'WheelchairDetection' */ './Pages/WheelChairDetection.vue');
const QuestionAnswering = () => import(/* webpackChunkName : 'WheelchairDetection' */ './Pages/QuestionAnswering.vue');

Vue.config.productionTip = false
Vue.use(Vuelidate);
// Vue.use(AOS);

// Vue.use(VueRouter,getAwesomeSwiper(SwiperClass));
Vue.use(VueRouter);
Vue.directive("lazyload", LazyLoadDirective);

Vue.mixin(TitleMixin);

const routes = [
  { path: '*', component: Home },
  {path: '/', component: Home},
  {path: '/services', component: Services},
  {path: '/about-us', component: AboutUs},
  {path: '/careers', component: Careers},
  {path: '/contact-us', component: ContactUS},
  {path: '/products', component: Products},
  {path: '/face-detection', component: FaceDetection},
  {path: '/age-prediction', component: AgeGenderprediction},
  {path: '/celebrity-recognition', component: CelebrityRecognition},
  {path: '/emotion-detection', component: EmotionDetection},
  {path: '/object-detection', component: ObjectDetection},
  {path: '/face-spoofing-detection', component: FaceSpoofingDetection},
  {path: '/face-compare', component: FaceCompare},
  {path: '/weapon-detection', component: WeaponDetection},
  {path: '/Wheelchair-detection', component: WheelchairDetection},
  {path: '/question-answering', component: QuestionAnswering},

];

const router = new VueRouter ({
  mode: 'history',
  routes
});

Vue.use(VueGtag, {
  config: { id: 'G-N7NTJHMGX8' }
});

new Vue({
  render: h => h(App),
  router,
  linkActiveClass: "active-link",
  mounted() {
    // document.body.querySelector('.base-layout').addEventListener('scroll', AOS.init);
    document.body.querySelector('.base-layout').addEventListener('scroll', AOS.refresh);
    this.$nextTick(() => {
      // AOS.init();
      document.addEventListener('DOMContentLoaded',()=>{
        AOS.init()
    })
    });
  }
}).$mount('#app')
