<template>
	<div tabindex="0" v-bind:id="'modal_' + id" v-bind:style="{ display: (isVisible ? 'block' : 'none') }" v-on:keydown="onKeyDown($event)">
		<div class="modal fade show">
			<div class="modal-dialog modal-lg" role="document" v-bind:style="{ width: width + 'px' }">
				<div class="modal-close">
					<button class="btn-transparent" v-on:click="close()"><x-icon class=""></x-icon></button>
				</div>
				<div class="modal-content">
					<div class="modal-body">
						<slot></slot>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-backdrop fade show"></div>
	</div>
</template>

<script>
	import XIcon from '../icons/CloseIcon';

	export default {
		components: {
			XIcon
		},

		methods: {
			close() {
				this.$emit('update:isVisible', false);
			},

			onKeyDown($event) {
				if ($event.keyCode === 27) {
					this.close();
				}
			}
		},

		props: {
			id: {
				type: String,
				default() {
					return parseInt(Math.random() * 100000, 10).toString();
				}
			},
			isVisible: Boolean,
			width: {
				default: 700,
				required: false
			}
		},
		watch: {
			isVisible: function (newValue) {
				let that = this;

				if (newValue) {
					setTimeout(function () {
						document.getElementById('modal_' + that.id).focus();
					});

				} 
			}
		}
	};
</script>

<style lang="scss">
	@import "../styles/components/BaseModal.scss";
</style>